export default {
  setMenu(rol) {
    let menu = [];
    switch (rol) {
      case 1: //Local
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Evaluación",
            path: "/evaluacion",
            icon: "mdi-file-document",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              {
                text: "Reporte Estadístico",
                path: "/admision/tablero",
                icon: "mdi-check",
              },
              {
                text: "Informe Estadístico",
                path: "/admision/informe/estadistico",
                icon: "mdi-check",
              },
              {
                text: "Reporte ESFM/UA",
                path: "/admision/reporte/institucion",
                icon: "mdi-check",
              },
              {
                text: "Habilitados valoración de lengua originaria",
                path: "/admision/habilitados/aptitud",
                icon: "mdi-check",
              },
              {
                text: "Inscritos modalidad B",
                path: "/admision/inscritos/modalidadb",
                icon: "mdi-check",
              },
              {
                text: "Habilitados valoración de lengua originaria gral",
                path: "/admision/habilitados/aptitud/gral",
                icon: "mdi-check",
              },
              {
                text: "Revisar valoración de lengua originaria",
                path: "/admision/revisar/aptitud",
                icon: "mdi-check",
              },
              {
                text: "Revisar habilitado escrita",
                path: "/admision/revisar/habilitado/escrita",
                icon: "mdi-check",
              },
              {
                text: "Habilitados escrita",
                path: "/admision/habilitados/escrita",
                icon: "mdi-check",
              },
              {
                text: "Generar y listar butaca",
                path: "/admision/generar/butaca",
                icon: "mdi-check",
              },
              {
                text: "Procesar resultados",
                path: "/admision/procesar/resultado",
                icon: "mdi-check",
              },
            ],
          },
          {
            text: "Evaluación D.P.",
            icon: "mdi-file-table",
            submenu: [
              {
                text: "Resultados",
                path: "/edp/resultado",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 2: //Seguimiento DGFM
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              {
                text: "Estadística",
                path: "/admision/tablero",
                icon: "mdi-check",
              },
              {
                text: "Reporte ESFM/UA",
                path: "/admision/informe/estadistico",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 8: //Director
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          /* {
            text: "Evaluación",
            path: "/evaluacion",
            icon: "mdi-file-document",
          }, */
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              {
                text: "Habilitados modalidad A",
                path: "/admision/habilitados/aptitud/esfm",
                icon: "mdi-check",
              },
              {
                text: "Inscritos modalidad B",
                path: "/admision/inscritos/modalidad/esfm/b",
                icon: "mdi-check",
              },
               {
                text: "Registro aptitud mod. A",
                path: "/admision/registro/aptitud",
                icon: "mdi-check",
              }, 
              {
                text: "Reporte computo mod. A",
                path: "/admision/reporte/aptitud/computo",
                icon: "mdi-check",
              },
               {
                text: "Registro modalidad B",
                path: "/admision/lista/postulante/b",
                icon: "mdi-check",
              }, 
              {
                text: "Reporte estadístico",
                path: "/admision/reporte/esfm",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 9: //Cajero
      case 10: //Contador
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              // {
              //   text: "Verificar depósito",
              //   path: "/admision/verificar/deposito",
              //   icon: "mdi-check",
              // }, 
              {
                text: "Lista verificados",
                path: "/admision/verificado/caja",
                icon: "mdi-check",
              },
              {
                text: "Lista verificados Diario",
                path: "/admision/verificado/diario/caja",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 11: //Coordinador
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              //  {
              //   text: "Verificar depósito",
              //   path: "/admision/verificar/deposito",
              //   icon: "mdi-check",
              // }, 
              {
                text: "Lista verificados",
                path: "/admision/verificado/caja",
                icon: "mdi-check",
              },
              {
                text: "Habilitados modalidad A",
                path: "/admision/habilitados/aptitud/esfm",
                icon: "mdi-check",
              },
              {
                text: "Inscritos modalidad B",
                path: "/admision/inscritos/modalidad/esfm/b",
                icon: "mdi-check",
              },
               {
                text: "Registro aptitud mod. A",
                path: "/admision/registro/aptitud",
                icon: "mdi-check",
              }, 
              {
                text: "Reporte computo mod. A",
                path: "/admision/reporte/aptitud/computo",
                icon: "mdi-check",
              },
               {
                text: "Registro modalidad B",
                path: "/admision/lista/postulante/b",
                icon: "mdi-check",
              }, 
              {
                text: "Reporte estadístico",
                path: "/admision/reporte/esfm",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 12: //Comision aptitud DGFM
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
              {
                text: "Revisar valoración de lengua originaria",
                path: "/admision/revisar/aptitud",
                icon: "mdi-check",
              },
              {
                text: "Estadística",
                path: "/admision/estadistica",
                icon: "mdi-check",
              },
              {
                text: "Reporte ESFM/UA",
                path: "/admision/reporte/institucion",
                icon: "mdi-check",
              },
            ],
          },
        ];
        break;
      case 13: //Seguimiento DGFM
      menu = [
        {
          text: "Inicio",
          path: "/",
          icon: "mdi-view-dashboard",
        },
        {
          text: "Admisión",
          icon: "mdi-file-document-edit",
          submenu: [
            {
              text: "Reporte Estadístico",
              path: "/admision/tablero",
              icon: "mdi-check",
            },
            {
              text: "Reporte ESFM/UA",
              path: "/admision/reporte/institucion",
              icon: "mdi-check",
            },
            {
              text: "Habilitados valoración de lengua originaria",
              path: "/admision/habilitados/aptitud",
              icon: "mdi-check",
            },
            {
              text: "Inscritos modalidad B",
              path: "/admision/inscritos/modalidadb",
              icon: "mdi-check",
            },
            {
              text: "Habilitados valoración de lengua originaria gral",
              path: "/admision/habilitados/aptitud/gral",
              icon: "mdi-check",
            },
            {
              text: "Revisar valoración de lengua originaria",
              path: "/admision/revisar/aptitud",
              icon: "mdi-check",
            },
            {
              text: "Revisión modalidad B",
              path: "/admision/lista/postulante/bdgfm",
              icon: "mdi-check",
            }, 
            {
              text: "Habilitados escrita",
              path: "/admision/habilitados/escrita",
              icon: "mdi-check",
            },
            {
              text: "Generar y listar butaca",
              path: "/admision/generar/butaca",
              icon: "mdi-check",
            },
            {
              text: "Procesar resultados",
              path: "/admision/procesar/resultado",
              icon: "mdi-check",
            },
           
          ],
        },



      ];
      break;
      default:
        menu = [
          {
            text: "Inicio",
            path: "/",
            icon: "mdi-view-dashboard",
          },
          {
            text: "Admisión",
            icon: "mdi-file-document-edit",
            submenu: [
               {
                  text: "Convocatoria",
                  path: "/",
                  icon: "mdi-check",
                }, 
               // se descomento este codigo
              //  {
              //   text: "Inscripción",
              //   path: "/admision/inscripcion",
              //   icon: "mdi-check",
              // },
              // {
              //   text: "Modificar datos",
              //   path: "/admision/modificar",
              //   icon: "mdi-check",
              // },
              // {
              //   text: "Descargar formulario",
              //   path: "/admision/impresion",
              //   icon: "mdi-check",
              // }, 
            // se descomento hasta aqui
              // {
              //   text: "Consultar butaca",
              //   path: "/admision/consultar/butaca",
              //   icon: "mdi-check",
              // },
              // {
              //   text: "Resultado final Mod. A",
              //   path: "/admision/consultar/resultado",
              //   icon: "mdi-check",
              // },
              // {
              //   text: "Resultado final Mod. B",
              //   path: "/admision/consultar/resultadoB",
              //   icon: "mdi-check",
              // },
            ],
          },
          // {
          //   text: "Banco de Profesionales  ",
          //   icon: "mdi-file-document-edit",
          //   submenu: [
          //     {
          //       text: "Convocatoria",
          //       path: "/banco/inscripcion",
          //       icon: "mdi-check",
          //     },
          //     // {
          //     //   text: "Registro",
          //     //   path: "/banco/documento",
          //     //   icon: "mdi-check",
          //     // },
          //     // {
          //     //   text: "Modificar datos",
          //     //   path: "/compulsa/modificar",
          //     //   icon: "mdi-check",
          //     // },
          //     {
          //       text: "Formulario de Registro",
          //       path: "/banco/formulario",
          //       icon: "mdi-check",
          //     }, 
          //   ],
          // },
          // {
          //   text: "Compulsa ESFM/UA",
          //   icon: "mdi-file-document-edit",
          //   submenu: [
          //     {
          //       text: "Convocatoria",
          //       path: "/compulsa/documento",
          //       icon: "mdi-check",
          //     },
          //     {
          //       text: "Inscripción",
          //       path: "/compulsa/inscripcion",
          //       icon: "mdi-check",
          //     },
          //     // {
          //     //   text: "Modificar datos",
          //     //   path: "/compulsa/modificar",
          //     //   icon: "mdi-check",
          //     // },
          //     {
          //       text: "Formulario de postulación",
          //       path: "/compulsa/formulario",
          //       icon: "mdi-check",
          //     }, 
          //     // {
          //     //   text: "Descargar certificado",
          //     //   path: "/compulsa/certificado",
          //     //   icon: "mdi-check",
          //     // },
          //   ],
          // },
          // {
          //   text: "U. Pedagógica",
          //   icon: "mdi-file-document-edit",
          //   submenu: [
          //     {
          //       text: "Convocatoria",
          //       path: "/compulsaUP/documento",
          //       icon: "mdi-check",
          //     },
          //     // {
          //     //   text: "Inscripción",
          //     //   path: "/compulsaUP/inscripcion",
          //     //   icon: "mdi-check",
          //     // },
          //     // {
          //     //   text: "Modificar datos",
          //     //   path: "/compulsa/modificar",
          //     //   icon: "mdi-check",
          //     // },
          //     // {
          //     //   text: "Formulario de postulación",
          //     //   path: "/compulsaUP/formulario",
          //     //   icon: "mdi-check",
          //     // }, 
          //     // {
          //     //   text: "Descargar certificado",
          //     //   path: "/compulsa/certificado",
          //     //   icon: "mdi-check",
          //     // },
          //     {
          //       text: "Hoja de respuestas",
          //       path: "/compulsa/respuestas",
          //       icon: "mdi-check",
          //     },
          //   ],
          // },
          /* {
              text: "Inscripción",
              path: "/inscripcion",
              icon: "mdi-bank",
            }, */
        ];
        break;
    }
    menu.push({
      text: "Guía de uso",
      icon: "mdi-file",
      submenu: [
        {
          text: "Admisión",
          path: "/guia/admision",
          icon: "mdi-check",
        },
        // {
        //   text: "Compulsa",
        //   path: "/guia/compulsa",
        //   icon: "mdi-check",
        // },
        // {
        //   text: "Institucionalización",
        //   path: "/guia/institucionalizacion",
        //   icon: "mdi-check",
        // },
      ],
    });
    return menu; //sessionStorage.setItem("mhya", JSON.stringify(menu));
  },
  getMenu() {
    let menuoff = [
      {
        text: "Inicio",
        path: "/",
        icon: "mdi-view-dashboard",
      },
      {
        text: "Admisión",
        icon: "mdi-file-document-edit",
        submenu: [
          {
            text: "Convocatoria",
            path: "/admision/convocatoria",
            icon: "mdi-check",
          },
          // {
          //   text: "Inscripción",
          //   path: "/admision/inscripcion",
          //   icon: "mdi-check",
          // },
          {
            text: "Modificar datos",
            path: "/admision/modificar",
            icon: "mdi-check",
          },
          {
            text: "Descargar formulario",
            path: "/admision/impresion",
            icon: "mdi-check",
          },
        ],
      },
      {
        text: "Guía de uso",
        icon: "mdi-file",
        submenu: [
          {
            text: "Institucionalización",
            path: "/guia/institucionalizacion",
            icon: "mdi-check",
          },
          /* {
            text: "Admisión",
            path: "/guia/admision",
            icon: "mdi-check",
          }, */
        ],
      },
    ];
    return sessionStorage.getItem("mhya")
      ? JSON.parse(sessionStorage.getItem("mhya"))
      : menuoff;
  },

  setRole(role) {
    return sessionStorage.setItem("ykilsa", role);
  },

  removeRole() {
    return sessionStorage.removeItem("ykilsa");
  },
};
